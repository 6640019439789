export default [
    {
        label: 'No.',
        field: 'orderNo',
        sort: true
    },
    {
        label: '회원ID',
        field: 'id',
        sort: false
    },
    {
        label: '이름',
        field: 'name',
        sort: true
    },
    {
        label: '발급일자',
        field: 'date',
        sort: false
    },
    {
        label: '발급번호',
        field: 'no',
        sort: false
    },
    {
        label: '',
        field: 'action',
        sort: false
    }
];
