<template>
    <div class="main-container">
      <h5 class="pageTitle" v-html="pageTitle"></h5>
      <mdb-container class="search-container">
        <mdb-row>
          <div class="ml-3">
            <mdb-select
                class="search-select"
                outline
                resetBtn
                v-model="selectOptions"
                placeholder="검색 항목"
                @getValue="getSelectValue"/>
          </div>
          <mdb-col>
            <mdb-input type="text" v-model="search" outline placeholder="검색어를 입력하세요."></mdb-input>
          </mdb-col>
        </mdb-row>
      </mdb-container>
      <mdb-container>
        <mdb-datatable-2
            v-model="data"
            class="text-center"
            striped
            bordered
            hover
            :searching="{value: search, field: fieldName} "            
            noFoundMessage="데이터가 없습니다."/>        
      </mdb-container>
    </div>
  </template>
  
  <script>
  import {mdbCol, mdbContainer, mdbDatatable2, mdbInput, mdbRow, mdbSelect} from "mdbvue";
  import certificate_list from "@/assets/data/columns/certificate_list";
  import axios from "@/axios";
  
  export default {
    name: "NonUserList",
    components: {
      mdbContainer,
      mdbDatatable2,
      mdbRow,
      mdbCol,
      mdbInput,
      mdbSelect,
    },
    data() {
      return {
        token: this.$store.state.token.access_token,
        pageTitle: '비행경력증명서 목록',
        data: {
          columns: certificate_list,
          rows: []
        },
        search: '',
        fieldName: '',
        selectOptions: [          
          {text: "ID", value: "id"},
          {text: "이름", value: "name"},
          {text: "발급일자", value: "date"},
          {text: "발급번호", value: "no"},          
        ],
        userType: 'approved'
      }
    },
    created() {
        window.onSelected = this.onSelected;
    },
    mounted() {
      this.init();
    },    
    methods: {
      init() {
        this.getList()
      },
      formatDate(date) {
        const year = date.getFullYear() % 100;
        const month = ('0' + (date.getMonth()+1)).slice(-2);
        const day = ('0' + date.getDate()).slice(-2);

        return `${year}.${month}.${day}`;
    },
    getList() {
        const self = this;
        self.data.rows.splice(0);   //data.rows 배열을 비움
        let orderNo = 0;        

        const config = {
            method: 'get',
            url: '/certificate/all',
            headers: {
            'Content-Type': 'application/json'
            }
        };

        axios(config)
            .then((querySnapshot) => {
                if (querySnapshot.data.size === 0) {
                    return
                }
                querySnapshot.data.forEach((doc) => {
                    if (doc.isvisible) {
                        orderNo++;
                        doc['orderNo'] = orderNo;                        
                        doc['date'] = this.formatDate(new Date(doc.date));
                        doc['action'] = '<button class="btn-detail" onclick="onSelected(\'' + doc.uuid + '\')">조회</button>';
                        self.data.rows.push(doc)
                    }
                })
            })
      },
    onSelected(value) {
        const self = this;
        const selData = self.data.rows.find((element) => element.uuid === value)
        delete selData.action
        self.$router.push({name: 'certificatemodify', params: {userInfo: null, certificateInfo: selData, source: 'reissue'}})
    },
    getSelectValue(value) {
        const self = this;
        self.fieldName = value
    },
    }
  }
  </script>
  
  <style scoped>
  .main-container {
    padding: 15px;
  }
  
  .pageTitle {
    font-weight: bold;
  }
  
  .search-select {
    width: 150px;
  }

  /deep/ .btn-detail {
  border: 1px solid #2e56f4;
  background: #2e56f4;
  border-radius: 4px;
  font-weight: 500;
  font-size: 16px;
  color: #FFFFFF;
  padding: 0 16px;
}
  </style>
  